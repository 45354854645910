<template>

 <div class="col-12"> 
 <h2>デバイス一覧</h2>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>デバイス</th>
              <th>ID</th>
              <th>状態</th>
            </tr>
          </thead>
          <tbody>
          
              <tr v-for="(device, key) in devices" :key="key">
                <td  v-if="device.product_id=='qzktzhehinzsz2je'">
                 <router-link :to="{ path: '/control/' + this.$route.params.device_id +'/air-conditioner/' + device.id  }"> {{ device.name }}</router-link>

                 </td>
                <td  v-if="device.product_id=='qzktzhehinzsz2je'">
               
                 <router-link :to="{ path: '/control/' + this.$route.params.device_id +'/air-conditioner/' + device.id  }"> {{ device.id }}</router-link>

                </td>
            
                <td  v-if="device.product_id=='qzktzhehinzsz2je'">                 
                 <span class="text-success" v-if="device.online">オンライン</span>
                 <span class="text-danger" v-if="!device.online">オフライン</span>
                 </td>
                </tr>
          </tbody>
        </table>
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';

export default defineComponent({
  name: "Devices",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  devices:  []
  } },
  computed: {
    inputType: function () {
      return this.isSharedKeyChecked  ? "text" : "password";
    },
    iconType: function () {
      return this.isSharedKeyChecked  ? faEye:faEyeSlash ;//"eye-slash" : "eye";
    }
  },
  mounted : function(){
      
      axios.get(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/devices/{device_id}/sub-devices&device_id="+ this.$route.params.device_id + process.env.VUE_APP_API_CODE
        ,
        { withCredentials: true }
        )
       .then( function( json ) {
           console.log(json);
            if( json.data){
                this.devices = json.data.result ;
                return true;
            }
            return false; 
      }.bind(this));  

  
   },
  methods: {
        onAccessKeyClick: function() {
            this.isSharedKeyChecked = !this.isSharedKeyChecked;
        },
        onAccessKeyDblClick: function (key){
           
            if (window.clipboardData) {
                window.clipboardData.setData('key', key);
               this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
           
            } else if (navigator.clipboard) {
                this.$emit('show-flash',{"message":key,"type": "primary"});
                navigator.clipboard.writeText(key)
                this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
            }
        }
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}